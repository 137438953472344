import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classes from './Alert.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';
import { setAlertIsOpen } from '../../store/actions/general';

const Alert = ({ socket }) => {
  const { banner } = window.sutherland_variables.ui;
  let alerts = !Array.isArray(banner) ? [] : banner;
  alerts = alerts.filter(data => data.enabled === true);

  const { muiFontLoaded, alertIsOpen } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(setAlertIsOpen(false));
  };

  alerts.sort((a, b) => {
    return a.order - b.order;
  });

  if (alertIsOpen) {
    return (
      <div>
        {
          alerts.slice(0, 1).map((alert) => {
            return (
              <div
              key={alert.order}
                className={`${classes.AlertContainer} ${
                  alert.type === 'success'
                    ? classes.AlertSuccess
                    : alert.type === 'warning'
                    ? classes.AlertWarning
                    : alert.type === 'info'
                    ? classes.AlertInfo
                    : classes.AlertError
                }`}
              >
                <div className={classes.AlertIconContainer}>
                  <button className={`${
                  alert.type === 'success'
                    ? classes.AlertTypeIconSuccess
                    : alert.type === 'warning'
                    ? classes.AlertTypeIconWarning
                    : alert.type === 'info'
                    ? classes.AlertTypeIconInfo
                    : classes.AlertTypeIconError
                }`}>
                    <MuiPlaceholder
                      isMuiFontLoaded={muiFontLoaded}
                      element={
                        alert.type === 'success' ? (
                          <div>check_circle</div>
                        ) : alert.type === 'warning' ? (
                          <div>warning</div>
                        ) : alert.type === 'info' ? (
                          <div>info</div>
                        ) : (
                          <div>cancel</div>
                        )
                      }
                    />
                  </button>
                </div>
                <div className={classes.AlertContentContainer}>
                  <div className={classes.AlertTitle}>
                    <ReactMarkdown
                      children={alert.title}
                      remarkPlugins={[remarkGfm]}
                    />
                  </div>
                  <div className={classes.AlertMessage}>
                    <ReactMarkdown
                      children={alert.body}
                      remarkPlugins={[remarkGfm]}
                      linkTarget='_blank'
                    />
                  </div>
                </div>
                <div className={classes.AlertCloseButtonContainer}>
                  <button
                    className={classes.AlertIconButton}
                    onClick={closeAlert}
                  >
                    <MuiPlaceholder
                      isMuiFontLoaded={muiFontLoaded}
                      element={<div>close</div>}
                    />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    );
 }
 return null;
};

export default Alert;
