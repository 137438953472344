import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import carouselClassesModule from "./Carousel.module.scss";
import MuiPlaceholder from '../../../../components/controls/MuiPlaceholder/MuiPlaceholder';

function CarouselComponent(props) {
  const { muiFontLoaded } = useSelector(state => state.general);
  const images = props.imagesData.map(data=>data.url);

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleCircleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);


  const slideStyle = {
    flex: "0 0 100%",
    transform: `translateX(-${currentIndex * 100}%)`,
    transition: "transform 1s ease-in-out",
    width: "100%",
    height: "100%",
    display: "none",
  };

  const activeStyle = {
    flex: "0 0 100%",
    transform: "translateX(0%)",
    transition: "transform 1s ease-in-out",
    width: "100%",
    height: "100%",
    display: "block",
  };

  return (
    <div className={carouselClassesModule.CarouselContainer}>
      <div className={`${carouselClassesModule.containerStyle} ${props.openInPopup === "false" ? carouselClassesModule.imageBorder : ''}`}>
          {images.map((image, index) => (
            <div
              key={index}
              style={index !== currentIndex ? slideStyle : activeStyle}
              onClick={props.openPopup}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          ))}

        <div className={carouselClassesModule.Controls}>
          <button
            onClick={prevSlide}
            className={carouselClassesModule.PrevButton}
          >
              <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>arrow_left</div>}
              width={30}
              height={30}
              backgroundColor={'#eeeeee'} />
          </button>
          <button
            onClick={nextSlide}
            className={carouselClassesModule.NextButton}
          >
              <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>arrow_right</div>}
              width={30}
              height={30}
              backgroundColor={'#eeeeee'} />
          </button>
        </div>
        <div className={carouselClassesModule.Circles}>
          <div>
            {images.map((_, index) => (
              <div
                key={index}
                className={
                  index === currentIndex
                    ? carouselClassesModule.CircleActive
                    : carouselClassesModule.Circle
                }
                onClick={() => handleCircleClick(index)}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

CarouselComponent.propTypes = {
  message: PropTypes.object,
};

export default CarouselComponent;
