import {
  SET_WIDGET_IS_OPEN,
  SET_DRAWER_IS_OPEN,
  SET_ENDCHAT_IS_OPEN,
  SET_AVATAR_URL, 
  SET_AGENT_NAME, 
  SET_PRECHAT_ENABLED, 
  SET_PRECHAT_FORM_OPENED,
  SET_SESSION_ID,
  SET_SOCKET_ID,
  MUI_FONT_LOADED,
  SET_RESET_CHAT,
  END_CHAT,
  SET_SESSION_START_TIME,
  SET_ALERT_IS_OPEN,
  SET_FINGERPRINT_ID,
} from '../constants/general';

export const setSessionId = (sessionId) => {
  return (dispatch) => {
    dispatch({
      type: SET_SESSION_ID,
      sessionId: sessionId
    });
  };
};

export const setSocketId = (socketId) => {
  return (dispatch) => {
    dispatch({
      type: SET_SOCKET_ID,
      socketId: socketId
    });
  };
};

export const setFingerprintId = (fingerprintId) => {
  return (dispatch) => {
    dispatch({
      type: SET_FINGERPRINT_ID,
      fingerprintId: fingerprintId
    });
  };
};

export const setAvatarUrl = (avatarUrl) => {
  return (dispatch) => {
    dispatch({
      type: SET_AVATAR_URL,
      avatarUrl: avatarUrl
    });
  };
};

export const setAgentName = (agentName) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGENT_NAME,
      agentName: agentName
    });
  };
};

export const setWidgetIsOpen = (widgetIsOpen) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_WIDGET_IS_OPEN,
      widgetIsOpen: widgetIsOpen
    });
  };
};

export const setDrawerIsOpen = (isOpen) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_DRAWER_IS_OPEN,
      drawerIsOpen: isOpen
    });
  };
};

export const setAlertIsOpen = (alertIsOpen) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ALERT_IS_OPEN,
      alertIsOpen: alertIsOpen
    });
  };
};

export const setPrechatEnabled = (prechatEnabled) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PRECHAT_ENABLED,
      prechatEnabled: prechatEnabled
    });
  };
};

export const setPrechatFormOpened = (prechatFormOpened) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PRECHAT_FORM_OPENED,
      prechatFormOpened: prechatFormOpened
    });
  };
};

export const setMuiFontLoaded = (isLoaded) => {
  return (dispatch, getState) => {
    dispatch({
      type: MUI_FONT_LOADED,
      muiFontLoaded: isLoaded
    });
  };
};

export const setResetChat = (isReset) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_RESET_CHAT,
      resetChat: isReset
    });
  };
};

export const setEndChat = (isEnd) => {
  return (dispatch, getState) => {
    dispatch({
      type: END_CHAT,
      endChat: isEnd
    });
  };
};

export const setSessionStartTime = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SESSION_START_TIME,
    });
  };
};

export const setEndChatIsOpen = (endChatIsOpen) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ENDCHAT_IS_OPEN,
      endChatIsOpen: endChatIsOpen
    });
  };
};