import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../components/Avatar/Avatar';
import FileTransfer from '../MessageBubbles/FileTransfer/FileTransfer';
import Incoming from '../MessageBubbles/Incoming/Incoming';
import IncomingAgent from '../MessageBubbles/IncomingAgent/IncomingAgent';
import Outgoing from '../MessageBubbles/Outgoing/Outgoing';
import SystemMessage from '../MessageBubbles/SystemMessage/SystemMessage';
import Carousel from '../MessageBubbles/Carousel/Carousel';
import Video from '../MessageBubbles/Video/Video';
import classes from '../MessageList.module.scss';

function MessageBubble(props) {
    const { index, message, isTop, isBottom, timeStamp, socket, avatarSize, avatarUrl, endChat } = props
    const cards = {
        "text_bot": <Incoming message={message} isTop={isTop} isBottom={isBottom} timeStamp={timeStamp} />,
        "text_user": <Outgoing message={message} isTop={isTop} isBottom={isBottom} timeStamp={timeStamp}/>,
        "text_agent": <IncomingAgent message={message} isTop={isTop} isBottom={isBottom} timeStamp={timeStamp} />,
        "file-transfer": <FileTransfer socket={socket} url={message.url} index={index} />,
        "status": <SystemMessage message={message} />,
        "carousel": <Carousel message={message} />,
        "video": <Video message={message} />,
    }

    const showAvatar = ["text_bot", "text_agent"]
    const type = (message.type === 'text') ? `text_${message.mode}` : message.type;

    if (type === "file-transfer" && endChat)
      return <></>
      
    const avatarContainer = type !== "status" ? <div
            className={classes.avatarContainer}
            style={{
              minWidth: avatarSize,
              height: isBottom ? avatarSize : 0,
              paddingTop: timeStamp === '' ? '0' : '40px'}}
          >
            {showAvatar.includes(type) && isBottom ? (
              <Avatar
                isAgent={message.mode === "agent"}
                height={window.sutherland_variables.ui.avatar_height? window.sutherland_variables.ui.avatar_height : 56}
                width={avatarSize}
                pictureUrl={avatarUrl}
                agentName={message.agentName}
              />
            ) : null}
          </div> : null

    return <>
      { !["carousel","video"].includes(type) && avatarContainer}
      {cards[type]}
    </>
}

MessageBubble.propTypes = {
  index: PropTypes.number,
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string,
  socket: PropTypes.object,
  avatarSize: PropTypes.number,
  avatarUrl: PropTypes.string,
  endChat: PropTypes.bool
};

export default MessageBubble;
