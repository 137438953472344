import React from "react";
import PropTypes from "prop-types";
import videoClassesModule from "./Video.module.scss";

function VideoComponent(props) {
  const thumbnailUrl = props.videoData.thumbnailUrl;
  const videoUrl = props.videoData.url;
  const title = props.videoData.title;

  const handleImageOnClick = () => {
    window.open(videoUrl)
  }

  return (
    <div className={videoClassesModule.VideoContainer}>
      <div
        className={`${videoClassesModule.containerStyle} ${videoClassesModule.imageBorder}`}
      >
        <div className={videoClassesModule.imageStyle} onClick={handleImageOnClick}>
          <img
            alt={title}
            src={thumbnailUrl}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
}

VideoComponent.propTypes = {
  message: PropTypes.object,
};

export default VideoComponent;
