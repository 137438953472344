import React from 'react';
import PropTypes from 'prop-types';
import classes from './Avatar.module.scss';

const Avatar = (props) => {

  if (!props.isAgent) {
    const style = {
      backgroundImage: `url(${props.pictureUrl})`,
      height: `${props.height}px`,
      width: `${props.width}px`
    };

    return <div className={classes.Avatar} style={style}></div>;
  } else {
    const avatarInitials = !props.agentName ? [''] : props.agentName.split(" ").map(word => word.charAt(0));
    return <div className={classes.AvatarAgent}>
      {avatarInitials.join('')}
    </div>;
  }
};

Avatar.propTypes = {
  isAgent: PropTypes.bool,
  pictureUrl: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default Avatar;
