import React, {useState} from 'react';
import PropTypes from 'prop-types';
import outgoingClassesModule from './Outgoing.module.scss';
import messageBubbleClassesModule from '../MessageBubble.module.scss';

function Outgoing(props) {
  const outgoingClasses = [outgoingClassesModule.Outgoing, messageBubbleClassesModule.MessageBubble];
  const configOutgoing = window.sutherland_variables.ui.outgoing_messages;
  const bubbleStyles = configOutgoing ? {
    color: configOutgoing.text_color,
    backgroundColor: configOutgoing.bubble_color,
    fontFamily: configOutgoing.font_family,
    boxShadow: configOutgoing.shadow,
    borderWidth: configOutgoing.border_width? configOutgoing.border_width : '',
    borderColor: configOutgoing.border_color? configOutgoing.border_color : ''
  } : {}
  const [ isDownloading, setIsDownloading ] = useState(false);

  const handleDownloadFile = (url) => {
    setIsDownloading(true);
    // Get the filename from the url
    const urlParams = new URLSearchParams(new URL(url).search);
    const filename = urlParams.get("name");
    
    fetch(url)
    .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.style.display = 'none';
      downloadLink.href = url;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
      setIsDownloading(false);
    })
    .catch(() => alert('oh no!'));
  }

  if (props.isTop) {
    outgoingClasses.push(outgoingClassesModule.top);
  }
  if (props.isBottom) {
    outgoingClasses.push(outgoingClassesModule.bottom);
  }

  if (props.message.isFileMessage) {
    return <div className={outgoingClassesModule.OutgoingContainer}>
      <div className={outgoingClasses.join(' ')} style={bubbleStyles}>
        You sent: <br/>
        <strong>{props.message.attachment.name}</strong> ({props.message.attachment.size} Bytes) <br />
        <button disabled={isDownloading} className={messageBubbleClassesModule.DownloadButton} onClick={()=>{handleDownloadFile(props.message.attachment.url)}}>{!isDownloading ? 'Download' : 'Downloading ...'}</button>
      </div>
      {props.isBottom ? (
        <div className={messageBubbleClassesModule.outgoingTimeStamp}>
          {props.timeStamp}
        </div>
      ) : null}
    </div>;  
  }

  return <div className={outgoingClassesModule.OutgoingContainer}>
    <div className={outgoingClasses.join(' ')} style={bubbleStyles}>
      {props.message.text}
    </div>
    {props.isBottom ? (
      <div className={messageBubbleClassesModule.outgoingTimeStamp}>
        {props.timeStamp}
      </div>
    ) : null}
  </div>;
}

Outgoing.propTypes = {
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string
};

export default Outgoing;
