import {
  SET_ZENDESK_SESSION,
  SET_FILE_UPLOAD_REQUEST_DETAILS,
  SET_FILE_CONFIG_DETAILS
} from '../constants/zendesk';

const initialState = {
  isAgentConnected: false,
  fileUrl: null,
  fileSizeLimit: 0,
  fileExtensions: ""
}

const zendesk = (state = initialState, action) => {
  switch (action.type) {
    case SET_ZENDESK_SESSION:
      return {
        ...state,
        isAgentConnected: action.isAgentConnected
      };
    case SET_FILE_UPLOAD_REQUEST_DETAILS:
      return {
        ...state,
        fileUrl: action.fileUrl,
        mid: action.mid,
        uid: action.uid
      };
    case SET_FILE_CONFIG_DETAILS:
      return {
        ...state,
        fileSizeLimit: action.fileSizeLimit,
        fileExtensions: action.fileExtensions
      }
    default:
      return state;
    }
  };

export default zendesk;