import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import incomingClassesModule from './IncomingAgent.module.scss';
import messageBubbleClassesModule from '../MessageBubble.module.scss';

function IncomingAgent(props) {
  const incomingClasses = [incomingClassesModule.IncomingAgent, messageBubbleClassesModule.MessageBubble];
  const configAgent = window.sutherland_variables.ui.agent_messages;
  const bubbleStyles = configAgent ? {
    color: configAgent.text_color,
    backgroundColor: configAgent.bubble_color,
    fontFamily: configAgent.font_family,
    boxShadow: configAgent.shadow,
  } : {}
  const [ isDownloading, setIsDownloading ] = useState(false);

  const handleDownloadFile = (url) => {
    setIsDownloading(true);
    // Get the filename from the url
    const urlParams = new URLSearchParams(new URL(url).search);
    const filename = urlParams.get("name");
    
    fetch(url)
    .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.style.display = 'none';
      downloadLink.href = url;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
      setIsDownloading(false);
    })
    .catch(() => alert('oh no!'));
  }

  if (props.isTop) {
    incomingClasses.push(incomingClassesModule.top);
  }
  if (props.isBottom) {
    incomingClasses.push(incomingClassesModule.bottom);
  }

  if (props.message.isFileMessage) {
    const fileName = props.message.attachment.name;
    const fileSize = props.message.attachment.size;
    const fileUrl = props.message.attachment.url;
    return <div>
      <div className={incomingClasses.join(' ')} style={bubbleStyles}>
        Agent sent: <br/>
        <strong>{fileName}</strong> ({fileSize} Bytes)
        <button disabled={isDownloading} className={messageBubbleClassesModule.DownloadButton} onClick={()=>{handleDownloadFile(fileUrl)}}>{!isDownloading ? 'Download' : 'Downloading ...'}</button>
      </div>
      {props.isBottom ? (
        <div className={messageBubbleClassesModule.outgoingTimeStamp}>
          {props.timeStamp}
        </div>
      ) : null}
    </div>;  
  }

  return (
    <div>
      <div className={incomingClasses.join(' ')} style={bubbleStyles}>
        <ReactMarkdown 
          children={props.message.text} 
          remarkPlugins={[remarkGfm]} 
          linkTarget="_blank"
        />
      </div>
      {props.isBottom ? (
        <div className={messageBubbleClassesModule.timeStamp}>
          {props.timeStamp}
        </div>
      ) : null}
    </div>
  );
}

IncomingAgent.propTypes = {
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string
};

export default IncomingAgent;
