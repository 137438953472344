import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CarouselComponent from './CarouselComponent';
import carouselPopupClassesModule from './CarouselPopup.module.scss';
import MuiPlaceholder from '../../../../components/controls/MuiPlaceholder/MuiPlaceholder';

function CarouselPopup({ onClose, imagesData }) {
  const { muiFontLoaded } = useSelector(state => state.general);
  const handleClose = () => {
    onClose();
  };

  return (
    <div className={carouselPopupClassesModule.PopupOverlay} onClick={handleClose}>
      <div className={carouselPopupClassesModule.Popup} onClick={(e) => e.stopPropagation()}>
        <div className={carouselPopupClassesModule.PopupContent}>
          <CarouselComponent imagesData={imagesData} />
        </div>
        <span className={carouselPopupClassesModule.CloseButton} onClick={handleClose}>
             <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>close</div>}
              width={30}
              height={30}
              backgroundColor={'#eeeeee'} />
        </span>
      </div>
    </div>
  );
}

CarouselPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CarouselPopup;
