import React from "react";
import PropTypes from "prop-types";
import VideoComponent from "./VideoComponent";

function Video(props) {
  return (
    <>
      {props && props.message && props.message.url && (
        <div>
          <VideoComponent
            videoData={props.message}
          />
        </div>
      )}
    </>
  );
}

Video.propTypes = {
  message: PropTypes.object,
};

export default Video;
