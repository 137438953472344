import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import { PERSIST, PURGE } from "redux-persist/es/constants";
import messages from "./reducers/messages";
import general from "./reducers/general";
import zendesk from "./reducers/zendesk";
import prechat from "./reducers/prechat";
import authentication from "./reducers/authentication";
import { setSessionId } from "../store/actions/general";
import { setMessages, setMode } from "../store/actions/messages";
import { setZendeskSession } from "../store/actions/zendesk";
import {
  setResetChat,
  setDrawerIsOpen,
  setWidgetIsOpen,
  setAlertIsOpen,
} from "../store/actions/general";

const component = document.getElementsByTagName("sutherland-messenger")[0];
const botId = component.getAttribute("bot-id");
const configKey = `${window.location.hostname}_${botId}_root`;

const persistConfig = {
  key: configKey,
  storage,
};

const rootReducer = combineReducers({
  messages: messages,
  general: general,
  prechat: prechat,
  authentication: authentication,
  zendesk: zendesk,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  applyMiddleware(
    ReduxThunk,
    createStateSyncMiddleware({
      blacklist: [PERSIST, PURGE],
    })
  )
);
initMessageListener(store);

store.subscribe(() => {
  // Check persist timeout
  const state = store.getState();
  const currentDate = new Date(); // Get the current date and time
  const sessionStart = new Date(state.general.sessionStartTime);
  const timeDifference = currentDate.getTime() - sessionStart.getTime(); // Calculate the difference in milliseconds
  const secondsElapsed = timeDifference / 1000; // Convert milliseconds to seconds
  const sessionExpirySeconds = 60 * 60 * 3; // 3 hours

  if (secondsElapsed > sessionExpirySeconds) {
    console.log("\u25B6 Chat session expired, restarting...");
    store.dispatch(setResetChat(true));
    store.dispatch(setWidgetIsOpen(false));
    store.dispatch(setDrawerIsOpen(false));
    store.dispatch(setAlertIsOpen(true));
    store.dispatch(setMode("bot"));
    store.dispatch(setSessionId(""));
    store.dispatch(setMessages([]));
    store.dispatch(setZendeskSession(false));
  }
});

export const persistor = persistStore(store);
