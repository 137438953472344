import React, { useState } from "react";
import PropTypes from "prop-types";
import CarouselComponent from "./CarouselComponent";
import CarouselPopup from "./CarouselPopup";

function Carousel(props) {
  const [popupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      {popupOpen ? <CarouselPopup onClose={closePopup} imagesData={props?.message?.parameters} openInPopup="true" /> : null}
      <div>
        <CarouselComponent openPopup={openPopup} imagesData={props?.message?.parameters} openInPopup="false" />
      </div>
    </>
  );
}

Carousel.propTypes = {
  message: PropTypes.object,
};

export default Carousel;
