import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classes from './MessageComposer.module.scss';
import Text from '../controls/Text/Text';
import Message from '../../models/Message';
import {
  addMessage,
  setQuickResponses,
  setShowUpload,
} from '../../store/actions/messages';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const MessageComposer = ({ socket }) => {
  const components = window.sutherland_variables.components;
  const { t } = useTranslation();
  const { sessionId } = useSelector((state) => state.general);
  const { isAgentConnected } = useSelector((state) => state.zendesk);
  const { mode } = useSelector((state) => state.messages);
  const [newMessage, setNewMessage] = useState('');
  const debouncedInputValue = useDebounce(newMessage, 500);
  const [isTypingState, setisTypingState] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [composerRef, setComposerRef] = useState(null);
  // eslint-disable-next-line 
  const [fileuploadEnabled, setFileuploadEnabled] = useState(components["fileupload"]["enabled"])
  
  const dispatch = useDispatch();
  const send = (textareaRef = null) => {
    if (newMessage.trim() !== '') {
      socket.emit("send", {
        message: newMessage,
        session_id: sessionId,
        mode: mode,
        messenger_id: window.sutherland_variables.botId,
      });
      if(textareaRef && textareaRef.current) { 
        textareaRef.current.style.height = 'inherit';
        setLimitReached(false);
      } else {
        composerRef.current.style.height = 'inherit';
        setLimitReached(false);
      }
      const message = {
        "text": newMessage,
        "mode": "user"
      };
      dispatch(addMessage(new Message(message)));
      dispatch(setQuickResponses([]));
      setNewMessage('');
      setisTypingState(false);
    }
  };

  const showUploadModal = () => {
    socket.emit('file-url-request', {});
    dispatch(setShowUpload(true));
  };

  const handleTypingStart = (message, textareaRef = null) => {
    setNewMessage(message);
    if(textareaRef) { 
      textareaRef.current.style.height = 'inherit';
      if(message.length === 250) {
        setLimitReached(true);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 20}px`;
      } else if (message.length === 0) {
        textareaRef.current.style.height = 'inherit';
        setLimitReached(false);
      } else {
        setLimitReached(false);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
      setComposerRef(textareaRef);
    }
    if (!isTypingState) {
      setisTypingState(true);
      socket.emit('is-typing', {});
    }
  };

  const stopTyping = useCallback(() => {
    socket.emit('stop-typing', {});
    setisTypingState(false);
  }, [socket]);

  useEffect(() => {
    if (
      newMessage === debouncedInputValue &&
      isTypingState &&
      newMessage !== ''
    ) {
      stopTyping();
    }
  }, [debouncedInputValue, newMessage, isTypingState, stopTyping]);

  return (
    <div className={classes.MessageComposer} style={window.sutherland_variables.ui.message_composer.container}>
      <div className={`${classes.textContainer} ${limitReached ? classes.limitReached : ''}`}>
        <Text
          value={newMessage}
          onChange={handleTypingStart}
          placeholder={t('composer.placeholder', 'Type your message here...')}
          onEnter={send}
          name='message'
          maxLength={250}
        />
        {limitReached && <p className={classes.error}>250-character limit reached</p> }
      </div>
      {isAgentConnected && fileuploadEnabled && (
        <>
        <button className={classes.AttachFile} onClick={showUploadModal}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.58851 6.8255L5.61332 14.6485C4.7979 16.2532 5.66981 18.3266 7.56768 19.2785C9.46555 20.2304 11.6625 19.6962 12.478 18.0915L12.8347 17.3894L15.23 12.6756L18.033 7.1594C18.6548 5.93582 18.0236 4.38585 16.6305 3.68712C15.2374 2.98839 13.6073 3.40418 12.9855 4.62777L11.6604 7.23542L9.72381 11.0466L7.68525 15.0584C7.28773 15.8407 7.65822 16.8068 8.48601 17.222C9.31381 17.6372 10.3162 17.3596 10.7138 16.5773L14.4851 9.15558C14.5972 8.93493 14.5072 8.66326 14.2852 8.55187C14.0631 8.44048 13.7896 8.52987 13.6775 8.75052L9.90616 16.1723C9.73288 16.5133 9.27733 16.612 8.89372 16.4196C8.51011 16.2272 8.31958 15.8044 8.49285 15.4634L10.5314 11.4517L12.468 7.64048L13.7931 5.03283C14.1906 4.25054 15.2738 4.01352 16.2228 4.48947C17.1717 4.96542 17.6229 5.97205 17.2254 6.75434L14.4224 12.2705L12.0271 16.9843L11.6704 17.6864C11.0792 18.8498 9.42908 19.2052 7.97539 18.4761C6.52171 17.747 5.82974 16.2169 6.42092 15.0535L10.3961 7.23057C10.5082 7.00992 10.4183 6.73825 10.1962 6.62686C9.97408 6.51547 9.70063 6.60486 9.58851 6.8255Z"
              fill="#FAB200"
              style={{fill: window.sutherland_variables.ui.message_composer.icon_colors}}
            />
          </svg>
        </button>
      </>
      )}

      <button className={classes.Send} onClick={send}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.71285 16.6211L17.4765 7.53953C17.6229 7.39982 17.33 7.12038 17.1835 7.2601L7.95505 15.0842L4.43945 13.6871C3.85352 13.4775 3.85352 12.709 4.43945 12.3598L19.3075 4.11647C19.9667 3.76718 20.6259 4.25619 20.4794 4.88492L18.2089 18.158C18.0624 18.647 17.5497 18.9264 17.1103 18.7169L13.1552 17.1101L11.0312 19.4853C10.5917 20.0442 9.71285 19.7647 9.71285 19.0662V16.6211Z"
            fill="#FAB200"
            style={{fill: window.sutherland_variables.ui.message_composer.icon_colors}}
          />
        </svg>
      </button>
    </div>
  );
};

export default MessageComposer;