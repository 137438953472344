/* eslint-disable no-undef */
import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import classes from './DrawerMenu.module.scss';
import { setMessages, setMode } from '../../store/actions/messages';
import { setZendeskSession } from '../../store/actions/zendesk'
import { setSessionId, setResetChat, setDrawerIsOpen, setEndChat, setAlertIsOpen } from '../../store/actions/general';
import PopOver from '../controls/PopOver/PopOver';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';

const DrawerMenu = ({socket}) => {
  const { drawerIsOpen, muiFontLoaded, sessionId, endChat, fingerprintId } = useSelector(state => state.general);
  const { isAgentConnected } = useSelector(state => state.zendesk);
  const isMounted = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, [])

  const handleOpen = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setDrawerIsOpen(drawerIsOpen ? false : true));
  }, [dispatch, drawerIsOpen]);

  const handleClose = useCallback((e) => {
    if (isMounted.current) {
      dispatch(setDrawerIsOpen(false));
    }
  }, [dispatch]);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${day}-${month}`;
  }

  async function downloadFile(blob, fileName) {
    try {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.style.display = "none"; // Hide the anchor
      document.body.appendChild(a); // Add the anchor to the DOM
      a.click(); // Simulate a click to trigger the download

      // Clean up
      URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }

  const downloadTranscript = useCallback((e) => {
    const configHeaders = {
      'Content-Type': 'application/json',
      'bot-config': window.sutherland_variables.botId
    }

    const urlParams = new URLSearchParams(window.location.search);
    let cacheDebug = JSON.parse(urlParams.get('enable_debug'));

    if (cacheDebug) {
      configHeaders['bypass-cache'] = "true";
    }

    fetch(`${window.sutherland_variables.engineBaseUrl}/api/transcript/download/${sessionId}`, {
      method: 'GET',
      headers: configHeaders
    })
    .then(async response => {
      downloadFile(await response.blob(), `Transcript_${formatDate(new Date())}.pdf`);
    })
    .catch(err => {
      console.error(err);
    });
  },[sessionId])

  const restartChat = useCallback(() => {
    dispatch(setDrawerIsOpen(false))
    if (!endChat) {
      setTimeout(() => {
        socket.emit("send", {
          name: "restart-chat-event",
          parameters:
          {
            FingerprintId: fingerprintId, 
            UserAgent: window.sutherland_variables.user_agent,
            Browser: window.sutherland_variables.browser,
            DeviceCategory: window.sutherland_variables.device,
            ScreenResolution: `${window.screen.width}x${window.screen.height}`,
            VisitorIP: window.sutherland_variables.ip_address,
            url: document.URL,
            title: document.title
          }
        });
        dispatch(setEndChat(false))
      }, 1000);
    } else {
      dispatch(setResetChat(true))
      dispatch(setEndChat(false))
      dispatch(setMode("bot"))
      dispatch(setSessionId(''))
      dispatch(setMessages([]))
      dispatch(setZendeskSession(false))
      dispatch(setAlertIsOpen(true))
      setTimeout(() => {
        socket.emit("reset", {});
      }, 1000);
    }
  }, [dispatch, socket, endChat, fingerprintId]);

  return (
    <div className={classes.DrawerMenu}>
      <div className={classes.DrawerMenuSVG} onClick={handleOpen}>
        <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.5 9C3.5 9.80406 2.82843 10.4559 2 10.4559C1.17157 10.4559 0.5 9.80406 0.5 9C0.5 8.19594 1.17157 7.54412 2 7.54412C2.82843 7.54412 3.5 8.19594 3.5 9ZM3.5 2.20588C3.5 3.00994 2.82843 3.66176 2 3.66176C1.17157 3.66176 0.5 3.00994 0.5 2.20588C0.5 1.40182 1.17157 0.75 2 0.75C2.82843 0.75 3.5 1.40182 3.5 2.20588ZM3.5 15.7941C3.5 16.5982 2.82843 17.25 2 17.25C1.17157 17.25 0.5 16.5982 0.5 15.7941C0.5 14.9901 1.17157 14.3382 2 14.3382C2.82843 14.3382 3.5 14.9901 3.5 15.7941Z" fill="#FAB200"
             style={{fill: window.sutherland_variables.ui.header_styles.icon_colors}}
            />
        </svg>
      </div>
      <PopOver close={handleClose} opened={drawerIsOpen}>
        <ul className={classes.MenuBox}>
          <li className={classes.MenuItem} onClick={downloadTranscript}>
            <div className={classes.MenuItemIcon}>
              <MuiPlaceholder
                isMuiFontLoaded={muiFontLoaded}
                element={<span aria-hidden="true">download_file</span>}
                width={0}
                height={0}
                backgroundColor={'transparent'} />
            </div>
            <div className="text">
              <Trans i18nKey="drawerMenu.downloadChat">
                Download Chat
              </Trans>
            </div>
          </li>
          {!isAgentConnected && <li className={classes.MenuItem} onClick={restartChat}>
            <div className={classes.MenuItemIcon}>
              <MuiPlaceholder
                  isMuiFontLoaded={muiFontLoaded}
                  element={<span aria-hidden="true">restart_alt</span>}
                  width={0}
                  height={0}
                  backgroundColor={'transparent'} />
            </div>
            <div className="text">
              <Trans i18nKey="drawerMenu.restartChat">
                Restart Chat
              </Trans>
            </div>
          </li>}
        </ul>
      </PopOver>
    </div>
  );
};
export default DrawerMenu;
