import {
    SET_ZENDESK_SESSION,
    SET_FILE_UPLOAD_REQUEST_DETAILS,
    SET_FILE_CONFIG_DETAILS
} from '../constants/zendesk';

export const setZendeskSession = (isAgentConnected) => {
    return (dispatch) => {
      dispatch({
        type: SET_ZENDESK_SESSION,
        isAgentConnected: isAgentConnected
      });
    };
};

export const setFileUploadRequestDetails = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_FILE_UPLOAD_REQUEST_DETAILS,
            fileUrl: data.fileUrl,
            mid: data.mid,
            uid: data.uid
        });
    };
}

export const setFileUploadConfigDetails = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_FILE_CONFIG_DETAILS,
            fileSizeLimit: data.file_size_limit,
            fileExtensions: data.file_extensions
        });
    };
}