import React from 'react';
import { Trans } from 'react-i18next';
import classes from './Header.module.scss';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setSessionId, setResetChat, setEndChat, setWidgetIsOpen, setAlertIsOpen, setDrawerIsOpen, setEndChatIsOpen } from '../../store/actions/general';
import { setMessages, setMode } from '../../store/actions/messages';
import { setZendeskSession } from '../../store/actions/zendesk'
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';
import Tooltip from '../controls/Tooltip/Tooltip';

const Header = ({socket}) => {
  const { muiFontLoaded, endChat } = useSelector(state => state.general);
  const dispatch = useDispatch();
  const headerImageStyle =  window.sutherland_variables.ui.header_styles.header_image ? window.sutherland_variables.ui.header_styles.header_image : {};

  const minimizeWidget = () => {
    dispatch(setWidgetIsOpen(false));
  };

  const closeWidget = () => {
    if (endChat) {
      dispatch(setWidgetIsOpen(false));
      dispatch(setResetChat(true))
      dispatch(setEndChat(false))
      dispatch(setMode("bot"))
      dispatch(setSessionId(''))
      dispatch(setMessages([]))
      dispatch(setZendeskSession(false))
      dispatch(setAlertIsOpen(true))
    } else {
      dispatch(setEndChatIsOpen(true))
      dispatch(setDrawerIsOpen(false));
    }
  };

  return (
    <div className={classes.Header} style={window.sutherland_variables.ui.header_styles.background}>
      <div className={classes.HeaderTitle} style={window.sutherland_variables.ui.header_styles.title}>
        {window.sutherland_variables.ui.header_image_url ? (
          <div className={classes.Image} style={headerImageStyle}>
            <div>
            <img
              src={window.sutherland_variables.ui.header_image_url}
              alt='Logo'
              style={{
                width: window.sutherland_variables.ui.header_image_size,
              }}
            />
            </div>
            <div>
            <Trans i18nKey="header.title">
              {window.sutherland_variables.ui.header_title}
            </Trans><br/>
            <Trans i18nKey="header.subTitle">
              <span className={classes.SubTitle} style={window.sutherland_variables.ui.header_styles.sub_title}>{window.sutherland_variables.ui.header_sub_title}</span>
            </Trans>
            </div>
          </div>
        ) : (
          <div>
            <Trans i18nKey="header.title">
              {window.sutherland_variables.ui.header_title}
            </Trans>
          </div>
        )}
        <div className={classes.HeaderButtons}>
          <DrawerMenu socket={socket}/>
          <Tooltip text="Minimize">
            <button className={classes.IconButton} onClick={minimizeWidget} style={{color: window.sutherland_variables.ui.header_styles.icon_colors}}>
              <MuiPlaceholder
                isMuiFontLoaded={muiFontLoaded}
                element={<div>remove</div>}
                width={30}
                height={30}
                backgroundColor={'#eeeeee'} />
            </button>
          </Tooltip>
          <Tooltip text="End chat">
            <button className={classes.IconButton} onClick={closeWidget} style={{color: window.sutherland_variables.ui.header_styles.icon_colors}}>
              <MuiPlaceholder
                isMuiFontLoaded={muiFontLoaded}
                element={<div>close</div>}
                width={30}
                height={30}
                backgroundColor={'#eeeeee'} />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Header;
