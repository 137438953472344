import React, {useCallback} from 'react';
import classes from './EndChatModal.module.scss';
import Modal from '../Modal/Modal';
import { setSessionId, setResetChat, setEndChat, setWidgetIsOpen, setAlertIsOpen, setEndChatIsOpen } from '../../store/actions/general';
import { setMessages, setMode } from '../../store/actions/messages';
import { setZendeskSession } from '../../store/actions/zendesk'
import { useDispatch, useSelector } from 'react-redux';

const EndChatModal = ({isOpen, closeHandler, socket}) => {
    const { isAgentConnected } = useSelector(state => state.zendesk);
    const headerStyle =  window.sutherland_variables.ui.end_chat_section ? window.sutherland_variables.ui.end_chat_section.header.h2 : {};
    const endBtnStyle =  window.sutherland_variables.ui.end_chat_section? window.sutherland_variables.ui.end_chat_section.end_btn : {};
    const dispatch = useDispatch();
    const endChat = useCallback(() => {
        const endEvent = isAgentConnected ? {from: "user"} : {from: "agent"}
        socket.emit("end-chat", endEvent);

        dispatch(setEndChatIsOpen(false));
        
        setTimeout(() => {
            dispatch(setWidgetIsOpen(false));
            dispatch(setResetChat(true))
            dispatch(setEndChat(false))
            dispatch(setMode("bot"))
            dispatch(setSessionId(''))
            dispatch(setMessages([]))
            dispatch(setZendeskSession(false))
            dispatch(setAlertIsOpen(true))
        }, 4000);
      }, [socket, isAgentConnected, dispatch]);
    return (
        <Modal isOpen={isOpen} onClose={closeHandler} contentWidth={'80%'}>
            <div className={classes.EndChatModalContainer}>
                <h2 style={headerStyle}>End chat</h2>
                <div className={classes.EndChatModalContent}>
                    <div className={classes.Label}>Are you sure you want to end this chat session?</div>
                    <div className={classes.Buttons}>
                        <button className={classes.ButtonEnd} onClick={endChat}  
                         style={endBtnStyle}  
                        >End Chat</button>
                        <button className={classes.ButtonCancel} onClick={closeHandler}>Cancel</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EndChatModal;
